
import { defineComponent, computed } from 'vue'
import OSection from 'theme/components/organisms/o-section.vue';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';

export default defineComponent({
  name: 'BordProducts',
  components: {
    MProductCarousel,
    OSection
  },
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  setup (props) {
    return {
      getBordProducts: computed(() => props.data)
    }
  }
})
